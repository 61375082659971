import { z } from "zod"
import { dineroStorableSchema } from "./Common"
import { aidString, mediumString, uuidString } from "./BaseStrings"
import { zodRegex } from "./zodExtensions"
import { transactionBatchIdSchema } from "./Transaction"

export const budgetSchema = z.object({
    uuid: uuidString,

    // /** Whether this is the currently applied budget. */
    // isCurrentBudget: z.boolean().optional(),

    /** The id of the batch of transactions added. */
    transactionBatchId: transactionBatchIdSchema.optional(),

    /** The date at which the budget has been applied. If set, the budget cannot be modified. */
    applicationDate: z.number().optional(),

    /** A map of category aids to budgeted amounts. */
    budgetedAmounts: z.record(dineroStorableSchema).optional(),

    /** The contributions to the budget. */
    contributions: z.array(
        z.object({
            /** The amount of the contribution. */
            amount: dineroStorableSchema,
            /** The aid of the accounting category to which the funds are going. */
            category: aidString,
            /** The name of the contribution. */
            name: mediumString.optional(),
            /** The end date of the contribution. */
            endDate: z.number().optional(),
            /**
             * The accounts the contribution applies to.
             *
             * Possible values are:
             * - `all:units`
             * - `all:parkings`
             * - `all:lockers`
             * - `units:${unit.number}` where `unit.number` is the NUMBER of a unit. Note that
             *   this is the NUMBER, not the AID, to ensure it still applies to units after they
             *   are sold.
             * - `parkings:${parking.uuid}` where `parking.uuid` is the uuid of a parking.
             * - `lockers:${locker.uuid}` where `locker.uuid` is the uuid of a locker.
             */
            appliesTo: z.array(
                z.union([
                    z.literal("all:units"),
                    z.literal("all:parkings"),
                    z.literal("all:lockers"),
                    zodRegex<`units:${number}`>(/^units:\d+$/),
                    zodRegex<`parkings:${string}`>(/^parkings:.+$/),
                    zodRegex<`lockers:${string}`>(/^lockers:.+$/),
                ])
            ),
        })
    ),
})
export type Budget = z.infer<typeof budgetSchema>
