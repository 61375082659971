import { z } from "zod"
import { objectWithAttachmentsSchema, objectWithUUIDSchema } from "./Common"
import { mediumString, shortMarkdownString } from "./BaseStrings"
import { zodRegex } from "./zodExtensions"

/** The maximum number of attachments a post can have. */
export const MAX_ATTACHMENTS_POST = 10

export const postRecipientSchema = zodRegex<
    "all" | "parkings" | "lockers" | "owners" | "residents" | `floor-${string}`
>(/^(all|parkings|lockers|owners|residents|floor-.+)$/)
export type PostRecipient = z.infer<typeof postRecipientSchema>

export const postSchema = objectWithUUIDSchema
    .merge(objectWithAttachmentsSchema(MAX_ATTACHMENTS_POST))
    .extend({
        date: z.number(),
        author: z.string().max(40),
        authorName: mediumString.optional(),
        title: mediumString,
        body: shortMarkdownString,
        recipients: z.array(postRecipientSchema),
        /** Whether to send an email notifications to the post recipients. Can only be activated by admins. */
        sendEmail: z.boolean().optional(),
    })
export type Post = z.infer<typeof postSchema>
