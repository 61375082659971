import { useAppSelector } from "./hooks"
import { userRoleSelector } from "store/appState"
import { Role } from "@constants/Role"

const permissionsCheck = {
    "accounts.read": [Role.approver, Role.admin, Role.accountant, Role.accountantWrite],
    "transactions.read": [
        Role.approver,
        Role.admin,
        Role.accountant,
        Role.accountantWrite,
        Role.owner,
    ],
    "transactions.write": [Role.approver, Role.admin, Role.accountantWrite],
    "people.read.all": [Role.approver, Role.admin, Role.accountant, Role.accountantWrite],
    "people.read.self": [Role.owner, Role.resident],
    "social.read": [Role.approver, Role.admin, Role.owner, Role.resident],
    "requests.emails": [Role.approver, Role.admin, Role.handler],
    "requests.create.for_self": [Role.owner, Role.resident],
    "requests.create.for_any": [Role.approver, Role.admin, Role.handler],
    "requests.tags.edit": [Role.approver, Role.admin, Role.handler],
    "budgets.edit": [Role.approver, Role.admin, Role.accountantWrite],
    "budgets.view": [Role.approver, Role.admin, Role.accountant, Role.accountantWrite, Role.owner],
    "units.close_account": [Role.approver, Role.admin, Role.accountantWrite],
}

export type PermissionKey = keyof typeof permissionsCheck | undefined

/** A custom hook that returns whether the user has a given permission. */
export function useHasPermission(permissionKey: PermissionKey) {
    const userRole = useAppSelector(userRoleSelector)

    if (!permissionKey) return true
    if (!userRole) return false

    return permissionsCheck[permissionKey].includes(userRole)
}
