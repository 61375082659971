import { z } from "zod"
import { aidString } from "./BaseStrings"

export const encryptedBankAccountSchema = z.union([
    // This is the legacy object.
    z.object({
        /** Contains encrypted information as a Firestore blob object. */
        ciphertext: z.unknown(),
        aid: aidString,
        /** The absence of `keyVersion` indicates that CloudKMS was used to encrypt the data. */
        keyVersion: z.undefined(),
    }),
    z.object({
        /** Contains encrypted information as a Firestore blob object. */
        ciphertext: z.string(),
        aid: aidString,
        /** The version of the key used to encrypt the data.
         *
         * If this is 1, the data was encrypted using AES-GCM and SubtleCrypto from the Web Crypto API.
         */
        keyVersion: z.literal(1),
        /** The IV used to encrypt the data, as a base64 string. */
        iv: z.string().base64(),
    }),
])
export type EncryptedBankAccount = z.infer<typeof encryptedBankAccountSchema>
