import { z } from "zod"
import { objectWithAddressSchema } from "./Address"
import { emailFrequencySchema, fiscalYearSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { currencySchema } from "./Currency"
import {
    aidString,
    buildingRefString,
    emailString,
    mediumString,
    phoneString,
    shortString,
    uuidString,
} from "./BaseStrings"
import { localeSchema } from "./Language"
import { PAYMENT_METHODS } from "../@constants/PaymentMethod"
import { Role } from "../@constants/Role"
import { billingAccountIdSchema, stripeSubscriptionIdSchema } from "./BillingAccount"

export const buildingSchema = objectWithAddressSchema.extend({
    name: mediumString.optional(),
    /** @deprecated Use `buildingRef` field instead. */
    uuid: uuidString,
    /** The date of the last edit. */
    lastModified: z.number().optional(),
    /** The id of building. Corresponds to the Firestore doc id. */
    buildingRef: buildingRefString,
    /** The email at which the building can be contacted.
     *
     * This is not verified (i.e., only use it for display purposes.)
     * This is NOT the same as `requestsEmail`, i.e., it is not an `@buildings.appnflat.com` email.
     */
    email: emailString.email({ message: ParsingErrors.invalid_email_address }).optional(),
    /** The phone number at which the building can be contacted.
     *
     * This is not verified (i.e., only use it for display purposes.)
     */
    phone: phoneString.optional(),
    /** The time zone of the building. Not editable. */
    timeZone: shortString.optional(),
    /** The currency of the building. Not editable. */
    currency: currencySchema,
    /** The default locale of the building.
     *
     * Used for communications and automatically created transactions descriptions.
     */
    locale: localeSchema.optional(),
    /** A list of all the fiscal years in the building. */
    fiscalYears: z.array(fiscalYearSchema),
    /** A list of the fiscal years in the building that can still be edited. */
    unarchivedFiscalYears: z.array(fiscalYearSchema),
    /** The bank account to which unit payments go, as well from which Otonom payments are done. */
    defaultBankAccountAID: aidString.optional(),
    /** A list of all the floors in the building. */
    floors: z.array(z.string()).optional(),
    /** Minimum amount a unit needs to have as an unpaid balance before we start charging penalties or interests. */
    minimumUnitBalanceBeforeChargingPenalties: z.number().gte(0).optional(),
    /** Whether we should send an email to the owners of a unit when a new check for that unit is saved. */
    sendEmailOnCheckSaved: z.boolean().optional(),
    /** The frequency with which to send an email to units with a missing insurance. */
    emailMissingDataInsuranceFrequency: emailFrequencySchema.optional(),
    /** The frequency with which to send an email to units with an insurance that is about to expire. */
    emailAboutToExpireInsuranceFrequency: emailFrequencySchema.optional(),
    /** How much time in advance to start emailing units about an insurance that is about to expire (in seconds). */
    emailAboutToExpireInsuranceStart: z.number().positive().optional(),
    /** The frequency with which to send an email to units with a missing water heater. */
    emailMissingDataWaterHeaterFrequency: emailFrequencySchema.optional(),
    /** The frequency with which to send an email to units with a water heater that is about to expire. */
    emailAboutToExpireWaterHeaterFrequency: emailFrequencySchema.optional(),
    /** How much time in advance to start emailing units about a water heater that is about to expire (in seconds). */
    emailAboutToExpireWaterHeaterStart: z.number().optional(),
    /** Whether to send emails to units with an unpaid balance. */
    emailUnpaidBalanceUnits: z.boolean().optional(),
    /** The Otonom identifier for the building (refered to as `clientPPA` in the Otonom documentation).
     *
     * If this information is absent, the building is not integrated with Otonom, so all corresponding
     * options should be hidden.
     */
    otonomId: z.string().max(30).optional(),
    /** Number of months after which unit is highlighted for not paying. */
    numberOfMonthsUnitHasUnpaidBalanceBeforeAlert: z.number().positive().optional(),
    /** The env of the building.
     *
     * Possible values are:
     * - `test`: used for testing purposes, automatically created by the tests.
     * - `prod`: used for production purposes. BE VERY CAREFUL WITH THESE BUILDINGS.
     * - `dev`: used for development purposes. Test code should not be run on this.
     * - `demo`: used for demo purposes. This is a production-like environment, but with fake data.
     * - `backup`: used for backup purposes. NO CODE SHOULD BE RUN ON THESE BUILDINGS.
     */
    env: z.enum(["test", "prod", "dev", "demo", "backup"]),
    /** Indicates that a building is used for tests. Should NOT be true for any non-internal buildings.
     * @deprecated Use `env` field instead.
     */
    isAlpha: z.boolean().optional(),
    /** If true, no emails should be sent to any resident, owner, etc. of this building. */
    sendNoEmails: z.boolean().optional(),

    // /** Payment information */
    // paidUntil: z.number().optional(),
    // freeTrialUntil: z.number().optional(),

    /** @deprecated See `billingAccount` instead. */
    stripeCustomerId: z.string().max(80).optional(),

    /** The billing account of the building. */
    billingAccount: billingAccountIdSchema.optional(),
    /** The stripe subscription id. Only present if the billing account uses stripe payments. */
    stripeSubscriptionId: stripeSubscriptionIdSchema.optional(),

    /** Whether owners are allowed to see all financial information. */
    ownersCanAccessFinances: z.boolean().optional(),

    /** A balance below which we will warn the user of a low balance on the bank account before making a payment. */
    lowBalanceWarningThreshold: z.number().gte(0).optional(),

    /** The maximum amount of storage that can be used by the building, in bytes. */
    storageSizeLimit: z.number().optional(),

    /** Number of approvals required.
     * The number of approvals required for a transaction to be considered approved.
     * Also determines the number of signatures that will be displayed on a printed check.
     */
    numberOfApprovalsRequired: z
        .union([z.literal(0), z.literal(1), z.literal(2), z.literal(3), z.literal(4)])
        .optional(),

    /** If true, this building should be entirely ignored. It is mostly used for backup purposes.
     * @deprecated Use `env` field instead.
     */
    frozen: z.boolean().optional(),

    /** The payment methods that require approval.
     *
     * If a transaction of kind `supplierPayment` uses one of these payment methods, it will
     * require approval. If this field is not present, all payment methods require approval.
     */
    paymentMethodsRequiringApproval: z.array(z.enum(PAYMENT_METHODS)).optional(),

    /** Whether to automatically send transactions of type `supplierPayment` and `recurringBillPayment` to Otonom. */
    otonomAutomaticallySend: z.boolean().optional(),

    /** The roles in the building that receive alerts when automated tasks fail. */
    rolesThatReceiveAlerts: z.array(z.nativeEnum(Role)).optional(),
})
export type Building = z.infer<typeof buildingSchema>
