import React, { useEffect, useState } from "react"
import { Button, Modal, Stack, Text, Title } from "@mantine/core"
import { useAppParams, useAppTranslation, usePermissions } from "hooks/hooks"
import { StripePortalButton } from "components/custom/StripePortalButton"
import { IconHeadset, IconLayoutDashboard } from "@tabler/icons-react"
import { isUseGetStatus, useGET } from "hooks/useGET"
import { useNavigate } from "react-router"
import { AppURLOptions, useAppURL } from "hooks/useAppURL"

/**
 * A screen that forces the user to pay for the building's subscription if they are admins or
 * informs them that the building is frozen if they are not.
 */
export default function ForcePaymentScreen({
    setBuildingDataLoadAllowed,
}: {
    /** A function to set whether the building data is allowed to be loaded. */
    setBuildingDataLoadAllowed: (allowed: boolean) => void
}) {
    const t = useAppTranslation(localTranslations)
    const role = usePermissions().isAdmin ? "Admin" : "User"
    const { buildingRef } = useAppParams()
    const [opened, setOpened] = useState(true)
    const navigate = useNavigate()
    const dashboardURL = useAppURL({
        path: ["dashboard"],
        options: { buildingRef: AppURLOptions.never, fiscalYear: AppURLOptions.never },
    })
    const [cache, setCache] = useState<"default" | "reload">("default")
    useEffect(() => {
        setCache("reload")
    }, [])
    const isUnpaidResponse = useGET(
        "/buildings/paid",
        { errorTitle: "silentError" },
        !buildingRef || { params: { query: { buildingRef } }, cache }
    )
    const paymentStatus =
        !buildingRef || isUseGetStatus(isUnpaidResponse) ? "paid" : isUnpaidResponse.status

    useEffect(() => {
        setBuildingDataLoadAllowed(paymentStatus === "paid")
    }, [paymentStatus, setBuildingDataLoadAllowed])

    if (paymentStatus === "paid") return null

    const withinGracePeriod = paymentStatus === "within-grace-period"
    const close = () => {
        if (withinGracePeriod) setOpened(false)
    }
    const goToDashboard = () => navigate(dashboardURL)
    const email = `mailto:help@appnflat.com?subject=${t("frozenBuilding")} [${buildingRef}]`

    return (
        <Modal fullScreen opened={opened} onClose={close} withCloseButton={withinGracePeriod}>
            <Stack justify="center" align="center" gap="lg">
                <Title order={1}>{t("title")}</Title>
                {t(`${withinGracePeriod ? "within" : "past"}GracePeriodMessage${role}`)}
                <StripePortalButton />
                {role === "Admin" && (
                    <Button component="a" href={email} leftSection={<IconHeadset />}>
                        {t("core:contact_support")}
                    </Button>
                )}
                <Button onClick={goToDashboard} leftSection={<IconLayoutDashboard />}>
                    {t("changeBuilding")}
                </Button>
                <Text c="gray" size="sm">
                    {t("justMadePayment")}
                </Text>
            </Stack>
        </Modal>
    )
}

const localTranslations = {
    title: {
        en: "AppNFlat Payment Required",
        fr: "Paiement AppNFlat Requis",
    },
    pastGracePeriodMessageAdmin: {
        en: "You have not paid AppNFlat for this building. Please pay via Stripe using the button below or reach out to support for more information.",
        fr: "Vous n'avez pas payé AppNFlat pour ce bâtiment. Veuillez payer via Stripe en utilisant le bouton ci-dessous ou contactez le support pour plus d'informations.",
    },
    pastGracePeriodMessageUser: {
        en: "The building's subscription has expired. Please contact the building administrator to resolve this issue.",
        fr: "L'abonnement du bâtiment a expiré. Veuillez contacter l'administrateur du bâtiment pour résoudre ce problème.",
    },
    withinGracePeriodMessageAdmin: {
        en: "Your building's subscription has expired and access will soon be blocked. Please pay via Stripe using the button below or reach out to support for more information.",
        fr: "L'abonnement de votre bâtiment a expiré et l'accès sera bientôt bloqué. Veuillez payer via Stripe en utilisant le bouton ci-dessous ou contactez le support pour plus d'informations.",
    },
    withinGracePeriodMessageUser: {
        en: "The building's subscription has expired and access will soon be blocked. Please contact the building administrator to resolve this issue.",
        fr: "L'abonnement du bâtiment a expiré et l'accès sera bientôt bloqué. Veuillez contacter l'administrateur du bâtiment pour résoudre ce problème.",
    },
    justMadePayment: {
        en: "Just made a payment? Refresh the page to continue.",
        fr: "Vous venez de faire un paiement ? Rafraîchissez la page pour continuer.",
    },
    changeBuilding: {
        en: "Change Building",
        fr: "Changer de Bâtiment",
    },
    frozenBuilding: {
        en: "Frozen Building",
        fr: "Bâtiment Gelé",
    },
}
