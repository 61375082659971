const core = {
    trial_balances: "Trial Balances",
    accountant_writings: "Accountant Writings",
    credit_notes: "Credit Notes",
    unit_payment: "Unit Payment",
    proxy: "Proxy",
    proxies: "Proxies",
    documentation: "Documentation",
    cancellation_reason: "Cancellation Reason",
    pre_authorized_debit: "Pre-authorized Debit",
    send_by_email: "Send by email",
    check_reversal_fee: "Check Reversal Fee",
    please_come_back_in_a_few_minutes: "Please come back in a few minutes.",
    app_under_maintenance: "AppNFlat is currently being updated.",
    you_are_already_signed_in: "You are already signed in",
    new_reverse_check: "Check Returned",
    notify_owners_by_email: "Notify the co-owner by email",
    to_add_penalty_first_set_amount_in_penalties_tab:
        "Please first define the penalty for NSF checks.",
    apply_penalty: "Apply NSF Penalty",
    "new_reverse_wire-transfer": "Wire-transfer Returned",
    list: "Back to list",
    request_handler: "Requests Manager",
    please_use_on_a_compouter_for_now:
        "Please use AppNFlat on a computer for now (if you are on a computer, please ensure the browser window is large enough).",
    coming_soon: "Coming soon",
    go_back_home: "Back to home page",
    mobile_not_yet_supported: "Mobile not yet supported",
    include_signatures: "Include Signatures",
    no_number_of_approvals_required_means_no_signatures_will_be_printed:
        "As no number of approval has been specified in the 'Administration' section, no signature will be printed.",
    printing_check: "Printing Check",
    request_approval_tip: "Approbations",
    no_approvals_required: "No approval required",
    signature_set: "Signature Set",
    delete_signature: "Delete Signature",
    number_of_approvals_required: "Number of approvals required",
    sign_in_box_above_then_save: "Please sign in the box above and then save.",
    clear: "Clear",
    signature: "Signature",
    change_signature: "Change signature",
    saving_signature: "Saving signature",
    archived_year: "Archived fiscal year",
    overpaid: "Overpayment",
    total_overpaid: "Total overpayment",
    error_loading_email: "An error occurred while loading the email.",
    attachments: "Attachments",
    add_file: "Add File",
    storage_usage: "Storage Usage",
    reply: "Reply",
    new_request_from_email: "+ New Request from this Email",
    assign_to_request: "Add Email to Existing Request",
    spam: "Spam",
    emails_unassigned_to_a_request: "Emails unassigned to a request",
    incoming_emails: "Incoming emails",
    inbox: "Inbox",
    trashed: "Trash",
    no_requests_to_assign_to: "No request to assign to",
    no_requests_to_merge_with: "No request to merge with",
    unit_renting: "Unit Renting Parking",
    all_units: "All Units",
    all_parkings: "All Parkings",
    all_lockers: "All Lockers",
    applies_to: "Applies to",
    no_monthly_fees: "No Monthly Fee",
    only_you_can_see_this: "Only you can see this, no one else in the building can.",
    low: "Low",
    medium: "Normal",
    high: "High",
    open: "Open",
    underway: "Ongoing",
    closed: "Closed",
    suggestions: "Suggestions",
    people: "People",
    new_request: "New Request",
    priority: "Priority",
    requestors: "Requestors",
    new_email: "New Email",
    subject: "Subject",
    new_request_tag: "New Request Tag",
    delete_request: "Delete Request",
    request: "Request",
    merge: "Merge requests",
    request_emails: "Emails of the request",
    select_an_email: "Select an email",
    read_by_colon: "Read by:",
    print_check: "Print Check",
    to_enable_ensure_payment_day_is_set:
        "To activate automatic payments, please enter the payment day information.",
    yourself: "Yourself",
    from_colon: "From:",
    subject_colon: "Subject:",
    read_by_color: "Read by:",
    requests: "Requests",
    handler: "Handler",
    tags: "Tags",
    color: "Color",
    delete_request_tag: "Delete request tag",
    edit_tags: "Edit tags",
    request_tags: "Request tags",
    and_NUMBER_more: "+ {{number}} more",
    // cannot_create_posts_as_you_are_banned:
    //     "Your building administrator prevented you from posting. Please contact him for any question.",
    file_deleted: "File Deleted",
    created_new_fiscal_year: "New fiscal year has been created",
    // problem_banning_user: "An error occurred while banning the user.",
    could_not_find_building: "Impossible to find the building.",
    // problem_recovering_email: "An error occurred while recovering email.",
    // problem_verifying_email: "An error occurred while verifying email.",
    month_was_reconciled: "Bank transactions have been reconciled for this month.",
    // error_sending_to_otonom: "An error occurred while sending the file to Otonom Solution",
    // error_loading_bank_account_info: "An error occurred while loading the bank account info.",
    // problem_sending_email_reminder: "An error occurred while sending the reminder email.",
    // problem_sending_verification_code: "An error occurred while sending the verification code.",
    // problem_creating_account: "An error occurred while creating the account.",
    // problem_updating_user_role: "An error occurred while updating user role.",
    // problem_removing_user: "An error occurred while removing user.",
    // problem_adding_users: "An error occurred while adding user.",
    // problem_inviting_users: "An error occurred while inviting user.",
    "+": "+",
    "1_month": "1 Month",
    "1_week": "1 Week",
    "2_months": "2 Months",
    "2_weeks": "2 Weeks",
    "total_accounts-payable": "TOTAL Accounts Payable",
    "total_accounts-receivable": "TOTAL Accounts Receivable",
    access_billing: "AppNFlat Subscription",
    access_has_ended_on_DATE: "Access has ended on {{date}}",
    access_will_end_on_DATE: "Access will end on {{date}}",
    account_number: "Bank Account #",
    account_statement: "Account Statement",
    account_transfer: "Transfer Between Accounts",
    account: "Account",
    accountant_view_and_write: "Accountant - View and write",
    accountant_view_only: "Accountant - View only",
    accounting_allotment: "Accounting Allotments",
    accounting_categories: "Accounts",
    accounting_id: "Accounting ID",
    accounting_number: "Account #",
    accounting: "Accounting",
    accounts_payable: "Accounts Payable",
    accounts_receivable_and_payable: "Accounts Receivable & Payable",
    accounts_receivable: "Accounts Receivable",
    active: "Active",
    actual_amount: "Actual Amount",
    add_a_new_part: "Add a new part",
    add_account: "+ Account",
    add_cash: "+ Cash",
    add_check: "+ Check",
    add_co_owner_resident: "+ Co-owner / Tenant",
    add_default_bank_account: "Add a Default Bank Account",
    add_email: "+ Email",
    add_locker: "+ Locker",
    add_multiple_following_checks: "Add a check sequence",
    add_new: "+",
    add_owner: "+ Owner",
    add_parking: "+ Parking",
    add_phone: "+ Phone",
    add_recurring_bill: "+ Recurring Invoice",
    add_resident: "+ Tenant",
    add_supplier: "+ Supplier",
    add_user: "+ User",
    address: "Address",
    administration: "Administration",
    administrator_with_approval: "Administrator approving payments",
    administrator: "Building Manager",
    all_owners_and_residents_with_a_locker: "All owners and tenants with a locker",
    all_owners_and_residents_with_a_parking: "All owners and tenants with a parking",
    all_owners: "All owners",
    all_posts: "All Posts",
    all_residents: "All tenants",
    all_transactions: "All Transactions",
    all_users: "All Users",
    all: "All",
    amount_adjusts_when_selecting_invoices: "Amount adjusts when selecting invoices.",
    amount_edited: "Edited Amount",
    amount: "Amount",
    monthly_contributions: "Monthly Contributions",
    apply_new_vote_shares: "Apply New Fractions",
    apply_non_retroactively: "Do not Apply Retroactively",
    apply_retroactively: "Apply Retroactively",
    appnflat_logo: "AppNFlat Logo",
    appnflat_payment_due: "AppNFlat Payment Due",
    AppNFlat: "AppNFlat",
    approval_status: "Approbation Status",
    approval: "Approvals",
    approvals: "Approvals",
    approve: "Approve",
    approved_and_paid_transactions: "Approved and Paid Transactions",
    approved_and_unpaid_transactions: "Approved and Unpaid Transactions",
    approved_by: "Approved By",
    approved_transactions: "Approved Transactions",
    approved: "Approved",
    archive_bank: "Archive Bank Account",
    archive_category: "Archive Account",
    archive_person: "Archive Person",
    archive_this_person: "Archive this person?",
    archive: "Archive",
    archived: "Archived",
    as_administrator: "As a building manager",
    as_yourself: "As Yourself",
    assets: "Assets",
    author: "Author",
    automatic_communications: "Automated Emails",
    automatic_deposit: "Automatic Deposit",
    automatic_interest_rate: "Interest Rate for late payments",
    automatic_penalties: "Penalties Automatically Charged",
    automatically_apply: "Automatic invoicing",
    available_parameters_are_$unitNumber_and_$amount_and_$link:
        "You can include in your email the unit number ($unitNumber), the amount ($amount) or a link ($link).",
    available_parameters_are_$unitNumber_and_$link:
        "You can include in your email the unit number ($unitNumber) or a link ($link).",
    back_to_the_app: "Back to the App",
    back: "Back",
    balance_at_beginning_of_the_year: "Balance at Beginning of the Year",
    balance_beginning_of_year: "Bank Account Balance Beginning of Year",
    balance_sheet: "Balance Sheet",
    balance: "Balance",
    balances_may_take_a_few_minutes_to_update_after_a_transaction_is_added_or_removed:
        "Balance may take a few minutes to update after a transaction is added or removed.",
    bank_account_for_condo_fees_payments: "Bank Receiving Condo Fees",
    bank_account_NAME_currently_has_balance_BALANCE_and_after_otonom_operation_will_have_balance_BALANCE_AFTER_OTONOM:
        "The balance of the account {{name}} is {{balance}}. After the transfer to Otonom Solution, the balance will be {{balanceAfterOtonom}}.",
    bank_account_NAME_currently_has_balance_BALANCE_and_after_transaction_will_have_balance_BALANCE_AFTER_TRANSACTION:
        "The balance of the account {{name}} is {{balance}}. After the transaction, the balance will be {{balanceAfterTransaction}}.",
    bank_account_name: "Bank Account Name",
    bank_account_number: "Account #",
    bank_account_type: "Bank Account Type",
    bank_account: "Bank Account",
    bank_accounts: "Bank Accounts",
    bank_fee: "Bank Fees",
    bank_institution_number: "Institution #",
    bank_reconciliation: "Reconciliation",
    bank_statement: "Bank Statement",
    bank_transit_number: "Transit #",
    bank: "Bank",
    banks: "Banks",
    banned_from_social: "Banned from posting",
    became_a_resident_on: "Tenant since",
    became_an_owner_on: "Owner since",
    beneficiary_bank_account: "Beneficiary Bank Account",
    bill_number: "Inv. #",
    billed_to_unit: "Invoiced To Unit",
    billing_day: "Invoicing Day",
    body: "Body",
    book_entry: "Book Entry",
    budget_application_date: "Budget Application Date",
    budget_total_amount: "Total Budget",
    budget: "Budget",
    budgeted_amount: "Budgeted Amount",
    building_administration: "Building Administration",
    building_name: "Building Name",
    building: "Building",
    cadastre_number: "Cadastre Number",
    caller: "Payed by",
    canadian_dollar: "Canadian Dollar",
    cancel_this_transaction: "Cancel this Transaction",
    cancel_transaction: "Cancel Transaction",
    cancel: "Cancel",
    cancelled: "Cancelled",
    capital_payed: "Capital Payment",
    capital: "Capital",
    cash_flow_statement: "Cash Flow Statement",
    cash_flow: "Cash Flow",
    cash: "Cash",
    category_name: "Account Name",
    category: "Account",
    cell: "Cell",
    change_file: "Change File",
    change_name: "Change Name",
    change_owner: "Change owner?",
    change_password: "Change Password",
    changing_password: "Changing password...",
    charges: "Charges",
    check_number: "Check #",
    check: "Check",
    checking_account: "Checking Account",
    checks_to_be_deposited: "Checks to be deposited",
    checks: "Checks",
    city: "City",
    click_to_create_deposit: "Click on the below button to create a new bank deposit.",
    click_to_register_check: "Click on the below button to register a check.",
    close_unit_account: "Close Unit Account",
    close: "Close",
    closing_balance_computed: "Closing Balance Computed",
    closing_balance_shown_on_bank_statement: "Closing balance on bank statement",
    closing_balance: "Closing Balance",
    co_owner_statement: "Co-owner Statement",
    co_owner: "Co-owner",
    co_owners_access_to_all_transactions: "Co-owners access to all transactions",
    co_owners_access_to_financials: "Co-owners access to financials",
    co_owners: "Co-owners",
    common_charges: "Common Charges",
    communications: "Communications",
    condo_fee_invoicing_language: "Language for Condo Fees Invoicing",
    condo_fee_payment: "Condo Fee Payment",
    condo_fees_allotment: "Condo Fees Allotment",
    condo_fees_to_pay: "Condo Fees to Pay",
    condo_fees_to_receive: "Condo Fees to Receive",
    condo_fees: "Condo Fees",
    confirm_sending_to_otonom: "Confirm sending to Otonom Solution",
    contact_support_if_issue_persists: "Please contact the support if the problem persists.",
    contact_support: "Contact Support",
    contact: "Contact",
    contingency_fund: "Contingency Fund",
    continue: "Continue",
    contributions: "Budget Contributions",
    counterpart: "Counterpart",
    country: "Country",
    create_an_account: "Create an account",
    create_email_template: "New Template",
    create_statement: "Create Statement",
    creating_account: "Creating Account...",
    credit_card: "Credit Card",
    credit_note: "Credit Note",
    credit_to_bank_account: "Credit To Bank Account",
    credit: "Credit",
    creditor: "Creditor",
    csv: "CSV",
    currency: "Currency",
    dashboard: "Dashboard",
    data_is_encrypted_using_industry_standards_such_as_aes_256:
        "Data is encrypted using industry standards such as AES 256.",
    data_is_only_used_to_process_payments_with_otonom:
        "Data is only used to process payments via Otonom Solution.",
    date_range: "Date Range",
    date: "Date",
    debit_card: "Debit Card",
    debit_to_bank_account: "Debit To Bank Account",
    debit: "Debit",
    debtor: "Debtor",
    default_bank_account_not_specified: "Default Bank Account Not Specified",
    delete_bank_account_info: "Delete Bank Account",
    delete_file: "Delete File",
    delete_locker: "Delete Locker",
    delete_parking: "Delete Parking",
    delete_penalty: "Delete Penalty",
    delete_this_user: "Delete this user?",
    delete_transaction_q: "Delete Transaction?",
    delete_transaction: "Delete Entire Transaction",
    delete_unit: "Delete Unit",
    delete_user: "Delete User",
    delete: "Delete",
    deleting_bank_account_info: "Deleting Bank Account Info...",
    deleting: "Deleting...",
    deposit_number: "Deposit #",
    deposit: "Deposit",
    deposited_amount: "Deposited Amount",
    deposits: "Deposits",
    description: "Description",
    difference: "Difference",
    do_you_wish_to_remove_locker: "Do you wish to delete the locker?",
    do_you_wish_to_remove_parking: "Do you wish to delete the parking?",
    done: "Done",
    download: "Download",
    due_to_suppliers: "Due to Suppliers",
    edit_budget: "Edit Budget",
    edit_email_template: "Edit Email Template",
    edit_owners: "Change Co-owner",
    edit_post: "Edit Post",
    edit_profile_picture: "Edit Profile Picture",
    edit_user: "User",
    edit: "Edit",
    email_insurance_about_to_expire_frequency: "Reminder frequency for insurance renewal",
    email_insurance_about_to_expire_start:
        "Timeline for notifying that insurance needs to be renewed",
    email_missing_data_insurance_frequency: "Reminder frequency for updating insurance data",
    email_missing_data_water_heater_frequency: "Reminder frequency for updating water heater data",
    email_recovered: "Email Recovered",
    email_templates: "Email Templates",
    email_topic: "Email Topic",
    email_verified: "Email Verified.",
    email_water_heater_about_to_expire_frequency: "Reminder frequency for replacing water heater",
    email_water_heater_about_to_expire_start:
        "Timeline for notifying that a water heater needs to be replaced",
    email: "Email",
    emergency_contact: "Emergency Contact",
    empty_if_no_automatic_payment: "Empty if no automatic payment",
    empty_if_no_end: "Empty if no end",
    end_date: "End Date",
    end_of_warranty: "End of Warranty",
    english_only: "english_only",
    ensure_difference_is_zero: "Please ensure difference is zero.",
    enter_a_name: "Enter a name",
    enter_a_valid_date: "Enter a valid date (later than today).",
    enter_a_valid_email: "Enter a valid email.",
    enter_a_valid_number: "Enter a valid number (up to two decimal places)",
    enter_a_valid_phone_number: "Enter a valid phone number",
    enter_a_valid_value: "Enter a valid value.",
    enter_your_password: "Enter your password.",
    error_regenerating_otonom_csv: "An error occurred while generating the Otonom Solution CSV.",
    error: "Error",
    essential_information_missing: "Essential Information Missing",
    euro: "Euro",
    everyone_in_the_building: "All co-owners and tenants",
    excel: "Excel",
    expense_account: "Expense Account",
    expense_category: "Expense Account",
    expense: "Expense",
    expenses: "Expenses",
    export: "Export",
    fax: "Fax",
    file_uploaded: "File Uploaded",
    financial_statement_section: "Financial Statement Section",
    financial_statements_parameters: "Financial Statements Parameters",
    financial_statements: "Financial Statements",
    financials: "Financials",
    first_name: "First Name",
    fiscal_year_end_k: "Fiscal Year End: {{date}}",
    fiscal_year_end: "Fiscal Year End",
    fiscal_year_k: "Fiscal Year {{year}}",
    fiscal_year: "Fiscal Year",
    floor_NUMBER: "Floor {{number}}",
    floor: "Floor",
    floors: "Floors",
    fraction: "Fraction",
    free_trial_ends_on_DATE: "Free Trial ends on {{date}}",
    free_trial: "Free Trial",
    from: "From",
    full_or_partial_payment: "Full or Partial Payment",
    full_payment: "Full Payment",
    gender: "Gender",
    generate_otonom_csv_for_units: "Collect Units' Payments via Otonom Solution",
    generate_otonom_csv: "Send payment to Otonom Solution",
    generates_a_csv_file_for_otonom_to_collect_unit_payments:
        "Sends collection request to Otonom Solution in order to receive payments from units paying via automatic wire transfer.",
    generating_otonom_csv: "Generating Otonom Solution CSV...",
    grace_period_in_days: "Grace Period (in days)",
    grace_period: "Grace Period",
    history: "History",
    home: "Home",
    i_agree_with_the_privacy_policy_and_terms_of_services:
        "I agree with the privacy policy and terms of services",
    i_agree_with_the_privacy_policy: "I agree with the privacy policy.",
    i_agree_with_the_terms_of_services: " I agree with the terms of services.",
    if_no_budget_units_will_not_be_automatically_billed:
        "Without a budget, units will not be charged automatically every month.",
    if_no_default_bank_account_units_will_not_automatically_pay:
        "Without a default bank account automatic payments by units cannot be activated.",
    if_you_did_not_change_your_email_please_immediately_change_your_password:
        "If you have not modified your email, please immediately change your password.",
    include: "Include:",
    income_statement: "Income Statement",
    income: "Income",
    incomplete: "Incomplete",
    informations: "Information",
    insert_title: "Insert Title",
    insert_unit_link: "Insert Unit Link",
    insert_unit_number: "Insert Unit Number",
    installation_date: "Installation Date",
    insurance_about_to_expire_reminder: "Reminder for insurance about to expire",
    insurance_about_to_expire: "Insurance is about to expire",
    insurance_certificate: "Certificate",
    insurance_incomplete_reminder: "Reminder for incomplete information on insurance",
    insurance_info_incomplete: "Insurance information is incomplete",
    insurance_invalid_or_missing_details: "Insurance is invalid or details are missing.",
    insurance_provider: "Provider",
    insurance_reminders: "Insurance Reminders",
    insurance: "Insurance",
    insurance_end_date: "End Date",
    interest_rate_p: "Interest Rate (%)",
    interest_rate: "Interest Rate",
    interests_payed: "Interest Payment",
    interests_received: "Interest Deposit",
    inv: "Inv.",
    invalid_value: "Invalid value",
    investment_account: "Investment Account",
    invite_bulk_users: "Invite bulk users",
    invited_as_ROLE: "Invited as {{role}}",
    invited_users: "Invited Users",
    invited: "Invited",
    inviting_users: "Inviting users...",
    invoice_from_supplier: "Invoice From Supplier",
    invoice: "Invoice",
    invoiced_k: "Invoiced: {{credits}}",
    invoiced: "Invoiced",
    invoices: "Invoices",
    invoicing_threshold: "Invoicing Threshold: ",
    journal_of_transactions: "Journal of Transactions",
    keep_balance_with_old_owner: "Keep balance with old owner",
    keyboard_shortcuts: "Keyboard Shortcuts",
    kind: "Type",
    last_name: "Last Name",
    late_payment_penalty: "Late Payment Penalty",
    leave_building: "Leave building",
    legal: "Legal",
    level: "Level",
    liabilities: "Liabilities",
    liability_insurance_coverage: "Liability Coverage",
    list_of_bank_accounts: "List of Bank Accounts",
    list_of_checks: "List of Checks",
    list_of_co_owners_and_residents: "List of Co-owners & Tenants",
    list_of_lockers: "List of Lockers",
    list_of_parkings: "List of Parkings",
    list_of_suppliers: "List of Suppliers",
    list_of_units: "List of Units",
    loading_and_decrypting_bank_account_info: "Loading and decrypting bank account information...",
    loading_report: "Loading Report...",
    loading: "Loading...",
    locker_k: "Locker {{number}}",
    locker_number: "Locker #",
    locker: "Locker",
    lockers: "Lockers",
    low_balance_threshold: "Balance alert threshold",
    man: "Male",
    manage_users: "Manage Users",
    missing_budget: "Condo Fees Budget Missing",
    month: "Month",
    monthly_cash_flows: "Monthly Cash Flows",
    monthly_exceptional_contribution: "Monthly Exceptional Contribution",
    monthly_fee: "Monthly Fee",
    monthly_fees: "Monthly Fees",
    monthly: "Once a Month",
    multiple: "Multiple",
    my_posts: "My Posts",
    name_is_same_across_buildings_and_is_shown_to_other_users_no_personal_information_is_sent_to_gravatar:
        "Your name is the same across all buildings and is shown to other users. No personal information is sent to Gravatar.",
    NAME_operating_account: "{{name}} (operating account)",
    name: "Name",
    never: "Never",
    new_bank: "New Bank",
    new_categories: "New Accounts",
    new_charge: "New Charge",
    new_checks: "New Checks",
    new_co_owner: "New Co-owner",
    new_condo_fees: "New Condo Fees",
    new_customFee: "New Penalty",
    new_deposit: "New Deposit",
    new_email_template: "New Email Template",
    new_emailTemplates: "New email template",
    new_locker: "New Locker",
    new_lockers: "New Lockers",
    new_parking: "New Parking",
    new_parkings: "New Parkings",
    new_password: "New Password",
    new_penalties: "New penalities",
    new_penalty: "New Penalty",
    new_person: "New Person",
    new_post: "New Post",
    new_posts: "New Posts",
    new_supplier: "New Supplier",
    new_suppliers: "New Suppliers",
    new_template: "New Template",
    new_transaction: "Transaction",
    new_unit: "New Unit",
    new_units: "New Units",
    no_currency_specified_please_contact_support: "No currency. Please contact support.",
    no_data: "No Data",
    no_file_uploaded: "No File Uploaded",
    no_locker: "No Locker",
    no_one: "No One",
    no_parent: "No Parent Account",
    no_parking: "No Parking",
    no_pending_invoice: "You do not have pending invoices",
    no_time_zone_specified_please_contact_support: "No time zone. Please contact support.",
    no_transactions_to_show: "No Transactions to Show.",
    no: "No",
    none: "None",
    notes: "Notes",
    number_of_checks: "Number of checks",
    number_of_months_where_unit_has_unpaid_balance:
        "Number of months since unit has an unpaid condo fees",
    number_of_weeks_after_which_to_send_email:
        "Number of weeks before reminder for unpaid condo fees",
    number: "Number",
    ok: "Ok",
    old_password: "Old Password",
    once_budget_applied_cannot_be_changed: "Once applied, budget cannot be changed",
    other: "Other",
    otonom_disabled_as_no_bank_account_specified:
        "Otonom Solution cannot be used until you have specified the bank account information of the recipient.",
    otonom_id_but_no_uploaded_bank_account_details: "Bank account info is missing.",
    otonom_id: "Otonom Solution ID",
    otonom_integration_is_disabled_go_to_admin_to_activate_it:
        "[Otonom Solution](https://www.otonomsolution.com) is a tool for collecting payments and transfer funds online.\n\nIf you want to use Otonom Solution make sure you have an account with them first.\n\nIf you are already an Otonom Solution customer, please visit the 'Administration / Building' section in order to activate the corresponding functionalities.",
    otonom_integration: "Otonom Activation",
    otonom_solution: "Otonom Solution",
    otonom: "Otonom Solution",
    overview: "Overview",
    owned_units: "Owned Units",
    owners_and_residents: "Owners & Tenants",
    owners_will_be_notified_by_email: "Owners will be notified by email.",
    owners: "Owners",
    page_not_found: "Page not Found",
    paid_k: "Paid: {{debits}}",
    paid: "Paid",
    parking_k: "Parking {{number}}",
    parking_number: "Parking #",
    parking: "Parking",
    parkings: "Parkings",
    partial_payment: "Partial Payment",
    password: "Password",
    payment_day: "Payment Day",
    payment_from_unit: "Payment From Unit",
    payment_method: "Payment Method",
    payment_status: "Payment Status",
    payment_to_supplier: "Payment To Supplier",
    payment: "Payment",
    payments: "Payments",
    pdf: "PDF",
    penalties_and_fees: "Charges & Penalties",
    penalties: "Penalties",
    penalty_amount: "Penalty Amount",
    penalty_description: "Penalty Description",
    penalty_name: "Penalty Name",
    penalty: "Penalty",
    pending_approval: "Pending Approval",
    pending_transactions: "Pending Transactions",
    pending: "Pending",
    person: "Person",
    phone_number: "Phone #",
    phone_type: "Phone Type",
    phone: "Phone",
    phones: "Phones",
    please_contact_support_as_soon_as_possible_to_get_help:
        "The application will not function, please contact our support.",
    please_fill_the_form: "Please complete the form",
    please_set_language_to_be_able_to_generate_reports:
        "Please set language in order to generate reports.",
    policy_number: "Policy #",
    post_content: "Post Content",
    post_has_no_content: "Post has no content.",
    post_title: "Post Title",
    posted_on: "Posted on",
    preferred_language: "Preferred language",
    previous_deposits: "Previous Deposits",
    previous_transfer: "Previous Transfers",
    previously_generated_otonom_files: "Payments sent to Otonom Solution during the last 30 days",
    privacy_policy: "Privacy Policy",
    problem_generating_otonom_csv_for_following_accounts:
        "An error occurred while generating the Otonom Solution data for the following accounts:",
    problem_generating_otonom_csv_for_following_units:
        "An error occurred, Otonom Solution data has not been generated for the following unit(s):",
    problem_generating_otonom_csv_for_n_transactions:
        "An error occurred, Otonom Solution data has not been generated for N transactions.",
    problem_generating_part_of_otonom_csv:
        "An error occurred, some of the Otonom Solution data has not been generated.",
    problem_getting_list_of_buildings: "An error occurred while listing the buildings.",
    problem_reconciling_transaction: "An error occurred while reconciling transaction.",
    problem_saving_supplier: "An error occurred while saving the supplier.",
    // problem_signing_in: "An error occurred while signing in.",
    profile_pictures_are_from_gravatar: "Profile pictures are from Gravatar",
    profile: "Profile",
    profit_and_loss: "Profit and loss",
    ready_to_send_to_otonom: "Ready to be sent to Otonom Solution",
    rec: "Rec.",
    receiving_account: "Receiving Account",
    recipients_k: "Recipients: {{recipients}}",
    recipients: "Recipients",
    reconciled: "Reconciled",
    recurring_bill: "Recurring Invoice",
    recurring_bills: "Recurring Invoices",
    recurring_end_date: "End Date",
    regenerate: "Regenerate",
    register_check: "Register Check",
    registered_checks: "Registered Checks",
    reject: "Reject",
    rejected_by: "Rejected By",
    rejected_transactions: "Transactions whose payment has been rejected",
    rejected: "Rejected",
    remaining_balance_processing: "Remaining Balance Processing",
    removed_amount: "Removed Amount",
    reneging_co_owners_accounts: "Reneging Co-owners' Accounts",
    rented: "Rented",
    person_renting: "Rented by",
    report_language_not_specified: "language for condo fees invoicing not specified",
    reports: "Reports",
    nudge_approval: "Send a reminder",
    request_approval: "Request Approval",
    required_field_missing: "A required field is missing",
    reserve_fund: "Reserve Fund",
    reset_changes: "Reset Changes",
    resident_with_access_to_financial_details: "Tenant with access to financial details",
    resident: "Tenant",
    residential_units: "Residential Units",
    residents: "Tenants",
    revenue_account: "Revenue Account",
    revenues: "Revenues",
    role: "Type",
    save_and_add_another: "Save & Add Another",
    save: "Save",
    saving_account: "Saving Account",
    saving: "Saving...",
    search: "Search",
    sections_to_include: "Sections to include",
    select_date_on_which_to_start_applying_budget_retroactively:
        "Date on which to start applying new budget",
    select_file: "Select File",
    select_transactions: "Select transactions",
    self_insurance_fund: "Self-Insurance Fund",
    send_email_on_check_save: "Confirm Received Checks Via Email:",
    send_email_to_recipients: "Send via email",
    send_email_to_units_with_unpaid_balance: "Send reminder email to units with unpaid condo fees",
    send_to_otonom_for_NAME: "Pay {{name}}",
    send_to_otonom: "Send to Otonom Solution",
    send_unit_payments_to_otonom:
        "Click on the below button, to send to Otonom Solution the payment of all your units paying via automatic wire transfer.",
    send_unit_statement: "Email Account Statement",
    sending_to_otonom: "Sending to Otonom Solution...",
    sent_to_otonom: "Sent to Otonom Solution",
    set_language: "Set Language",
    settings: "General Settings",
    shortcuts: "Shortcuts",
    show_help: "Display Help",
    sign_in: "Sign In",
    sign_out: "Sign Out",
    sign_up: "Sign Up",
    signing_in: "Signing In...",
    signing_out: "Signing Out...",
    start_date: "Start Date",
    start_new_fiscal_year: "Start New Fiscal Year",
    starting_balance: "Starting balance",
    state: "State",
    status: "Status",
    sterling_pound: "Sterling Pound",
    street: "Street",
    success: "Operation successfully completed",
    supplier_name: "Supplier Name",
    supplier_payment: "Suppliers payment",
    supplier_statement: "Supplier Statement",
    supplier_transactions_ready_to_send_to_otonom:
        "Supplier payments to be sent to Otonom Solution",
    supplier: "Supplier",
    suppliers_SUPPLIERS_have_transactions_to_send_to_otonom: "{{suppliers}}",
    suppliers: "Suppliers",
    terms_of_services: "Terms of Services",
    threshold_for_automatic_billing: "Threshold for Automatic Billing",
    time_zone: "Time Zone",
    title: "Title",
    to_add_or_remove_units_and_parkings_please_contact_support:
        "To add or remove units or parkings, please contact our support.",
    to_enroll_unit_in_automatic_payment_enter_bank_account_details:
        "To activate the automatic wire transfer, please enter the bank account information of the payer.",
    to: "To",
    toggle_navigation: "Menu",
    total_assets: "TOTAL Assets",
    total_capital_and_liabilities: "TOTAL Capital & Liabilities",
    total_capital: "TOTAL Capital",
    total_expenses: "TOTAL Expenses",
    total_k: "Total: {{total}}",
    total_liabilities: "TOTAL Liabilities",
    total_revenues: "TOTAL Revenues",
    total: "Total",
    transaction: "Transaction",
    transactions_export: "Transactions Export",
    transactions: "Transactions",
    transfer_balance_to_new_owner: "Transfer balance to new owner",
    transfer_to_new_owner_or_keep_on_old_owner:
        "Do you want to transfer the balance to the new owner?",
    transfers: "Transfers",
    unit_k: "Unit {{number}}",
    unit_NUMBER_sold: "Unit {{number}} (sold)",
    unit_number: "Unit #",
    unit_statement: "Unit Statement",
    unit_transactions_ready_to_send_to_otonom: "Unit payments to be sent to Otonom Solution",
    unit_unpaid_balance_reminder: "Reminder for unpaid balance on condo fees",
    unit: "Unit",
    united_states_dollar: "American Dollar",
    units_collection: "Collection from co-owners",
    units_parkings_and_lockers: "Units, Parkings & Lockers",
    units_paying_by_automatic_wire_transfers: "Units Paying by Wire Transfer",
    units_UNITS_have_transactions_to_send_to_otonom: "Units {{units}}",
    units_with_unpaid_balance: "Units with unpaid condo fees",
    units_without_insurance: "Units Without Insurance",
    units: "Units",
    unknown_action: "Unknown Action",
    unknown_building: "Unknown building",
    unknown_error: "Unknown Error",
    unknown_name: "Unknown Name",
    unknown_role: "Unknown Role",
    unknown: "Unknown",
    unpaid_balance_after_k_weeks: "Unpaid Condo Fees for {{k}} Weeks",
    unpaid_balance_reminder: "Reminder for unpaid condo fees",
    update_bank_account: "Update Bank Account",
    update_password: "Update Password",
    update_payment_info: "Update",
    update: "Update",
    upload_file: "Upload File",
    upload: "Upload",
    uploaded_bank_account_details_but_no_otonom_id: "Bank account has no Otonom Solution Id.",
    uploading: "Uploading...",
    users: "Users",
    v_owners_and_residents_with_a_locker: ", Owners and tenants with a locker",
    v_owners_and_residents_with_a_parking: ", Owners and tenants with a parking",
    v_owners: ", Owners",
    v_residents: ", Tenants",
    vehicle_license_plate: "License Plate",
    verification_code: "Verification Code",
    view_of_montreal: "View of Montréal",
    view_units_paying_by_automatic_wire_transfers:
        "Click on the below button to view all units paying via wire transfer.",
    virgule: ",",
    vote_share_total_is_not_100: "The sum of voting rights is not equal to 100%.",
    vote_share: "Vote Share",
    vote_shares: "Vote Shares",
    waiting_approval: "Waiting for Approval",
    water_heater_about_to_expire_reminder: "Reminder for water heater warranty about to expire",
    water_heater_about_to_expire: "Water heater warranty about to expire",
    water_heater_incomplete_reminder: "Reminder for incomplete information on water heater",
    water_heater_info_incomplete: "Water heater information incomplete",
    water_heater_invalid_or_missing_details: "Details are missing.",
    water_heater_label: "Label",
    water_heater_reminders: "Water Heater Reminders",
    water_heater: "Water Heater",
    water_heaters_to_be_replaced: "Water Heaters to be Replaced",
    weekly: "Once a Week",
    welcome_back: "Welcome Back",
    wire_transfer: "Wire Transfer",
    wire_transfers: "Wire Transfers",
    woman: "Female",
    work: "Work",
    yearly: "Once a Year",
    yes: "Yes",
    your_buildings: "Your Buildings",
    zip_code: "Zip Code",
    file_format: "File Format",
} as const

export default core
